<template>
  <div class="guide">
    <div class="banner-size">
      <div class="banner-limit banner-info">
        <span class="banner-fMax">项目详情</span>
        <span class="banner-fMin banner-other"
          >送政策、送服务，助推企业发展</span
        >
      </div>
    </div>
    <div ref="guideContainer" class="width-limit guide-container">
      <div>
        <div class="menus">
          <!-- 左侧导航 -->
          <div class="left-part">
            <Anchor show-ink :offset-top="offsetTop">
              <AnchorLink
                v-for="(menu, i) in menus"
                :key="i"
                :href="`#${menu.name}`"
                :title="menu.category"
              >
              </AnchorLink>
            </Anchor>
          </div>
          <div class="right-part">
            <div class="right-header">
              <!-- 主要标题 -->
              <div class="h-title max-two-line">
                {{ projectName }}
              </div>
              <!-- 申报按钮 -->
              <div class="h-btns">
                <Button
                  disabled
                  type="primary"
                  class="h-btn"
                  @click="gotoDeclare"
                  >立即申报</Button
                >
                <Button type="primary" class="h-btn" @click="gotoDetail"
                  >查看详细指南</Button
                >
                <!-- <Button icon="ios-download-outline" class="h-btn">关注</Button> -->
              </div>
            </div>
            <div class="flex-row-between menu-content">
              <div class="main-content">
                <ShenpiStreamline
                  ref="guideCenter"
                  :baseInfo="baseInfo"
                  :rangeInfo="rangeInfo"
                  :processInfo="processInfo"
                  :groupArr="groupArr"
                  :matInfo="matInfo"
                  :consultInfo="consultInfo"
                  :windowInfo="windowInfo"
                  :chargeInfo="chargeInfo"
                  :intermediaryInfo="intermediaryInfo"
                  :shedingyiju="shedingyiju"
                  :lawInfo="lawInfo"
                ></ShenpiStreamline>
              </div>
              <div class="other-content">
                <!-- <GuideRight :baseItem="baseItem" @gotoDeclare="gotoDeclare"></GuideRight> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ShenpiStreamline from "./components/guide/shenpiStreamline";
// import GuideRight from "./components/guide/guideRight";

import Check from "../../utils/check";
import GuideDataCenter from "./guideShenpiDataCenter";
import { Loading } from "element-ui";

export default {
  name: "guide",
  components: {
    ShenpiStreamline,
    // GuideRight,
  },
  computed: {
    ...mapGetters({
      // childItem: "getCurrentDeclareItem",
      guideId: "getCurrentGuideId",
    }),
  },
  data() {
    return {
      projectName: "", // 名称
      offsetTop: 28,
      baseItem: undefined,
      /* 注意menus数组中的name要跟*/
      menus: [
        {
          id: 0,
          name: "jibenxinxi",
          category: "基本信息",
        },
        {
          id: 1,
          name: "banliliucheng",
          category: "办理流程",
        },
      ],
      // 需要的信息
      baseInfo: undefined, // 基本信息
      rangeInfo: undefined, // 范围条件
      processInfo: [], // 办理流程
      groupArr: [], // 申请材料
      matInfo: [],
      consultInfo: [], // 咨询监督
      windowInfo: [], // 窗口办理
      chargeInfo: [], // 许可收费
      intermediaryInfo: [], // 中介服务
      shedingyiju: [], // 设立依据
      lawInfo: [], // 法律救济
    };
  },
  created() {},
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      // console.log("guideId:", this.guideId);
      // this.guideId = "11440306574755306X444011100600003";
      // this.guideId = "11440306574755306X4442111808002"
      if (!Check.isNotNull(this.guideId)) {
        //如果ID为null 则返回
        return;
      }
      let params = {
        PERMID: this.guideId,
      };
      let loadingInstance = Loading.service({
        target: this.$refs.guideContainer,
      });
      let localInfo = localStorage.getItem(this.guideId);
      if (Check.isNotNull(localInfo)) {
        // let data = JSON.parse(localInfo);
        this.handleData(JSON.parse(localInfo), loadingInstance);
      } else {
        this.$httpApi
          .get(`/dev-api/basicinfo/baseitem/queryBszn?permId=${this.guideId}`)
          .then((res) => {
            console.log("res item:", res);
            if (res.code != 200) {
              return;
            }
            // let resRetrun = JSON.stringify(res.ReturnValue);
            localStorage.setItem(this.guideId, res.data);
            this.handleData(JSON.parse(res.data), loadingInstance);
          })
		  .catch((err) => {
		    
		  });
        // getStyPermissionByPermid(params, (res) => {});
      }
    },
    /* 处理基本事项 */
    handleData(data, loadingInstance) {
      if (data) {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close();
        });
        this.baseInfo = GuideDataCenter.getBaseInfoGuideArr(data.ITEM); // 基本信息
        this.projectName = this.baseInfo[0].CONTENT;
        //   console.log("基本信息", this.baseInfo)
        this.rangeInfo = GuideDataCenter.getRangeGuideArr(data.ITEM); // 范围条件
        //   console.log("范围条件", this.rangeInfo)
        this.processInfo = GuideDataCenter.getProcessGuideArr(data.ITEM); // 办理流程
        //   console.log("办理流程", this.processInfo)
        this.groupArr = data.CLQX; // 申请材料
        //   console.log("groupArr", this.groupArr)
        if (this.groupArr.length == 0) {
          this.matInfo = GuideDataCenter.handleMatText(data.CLXX);
        } else {
          this.selectG = this.groupArr[0];
          this.matArr = GuideDataCenter.getMatGuideArr(
            this.groupArr,
            data.CLXX
          );
          this.matInfo = GuideDataCenter.getMatGuideArrByOrder(
            this.matArr,
            this.selectG.ORDERNUMBER
          );
        }
        // console.log("matInfo>>>", this.matInfo)
        this.consultInfo = GuideDataCenter.getConsultGuideArr(data.ITEM); // 咨询监督

        this.windowInfo = data.CKXX; // 窗口信息
        // console.log("windowInfo>>>", this.windowInfo)
        this.chargeInfo = data.XKSF; // 许可收费
        // console.log("许可收费>>>", this.chargeInfo)
        this.intermediaryInfo = data.ZJFW; // 中介服务

        this.shedingyiju = data.SDYJ; // 设立依据

        this.lawInfo = GuideDataCenter.getLawGuideArr(data.ITEM); // 法律救济

        // console.log("item:", this.consultInfo, this.lawInfo);
      }
    },
    /* 转跳到精简办事指南 */
    gotoDetail() {
      this.$router.push({
        path: "/guideShenpi",
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/index.scss";
.banner-size {
  background: url("../../assets/images/icon_banner_declare.png");
}
.banner-info {
  height: 100%;
  display: flex;
  align-items: center;
  .banner-other {
    margin-left: 15px;
  }
}

.guide-container {
  min-height: 500px;
  overflow: hidden;
  background: #ffffff;
  .menus {
    display: flex;
    flex-direction: row;
  }
  .left-part {
    width: 220px;
    padding-left: 30px;
    padding-top: 150px;
  }
  .right-part {
    flex: 1;

    .right-header {
      margin-bottom: 20px;
      margin-left: 30px;
      .h-title {
        padding: 30px 0 10px 0;
        font-size: 24px;
        color: rgb(13, 28, 40);
        font-weight: 600;
        max-width: 700px;
        height: 90px;
        overflow: hidden;
      }
      .h-btn {
        margin-right: 10px;
      }
    }

    .menu-content {
      align-items: flex-start;
      .main-content {
        flex: 1;
        padding-left: 30px;
        padding-right: 30px;
      }
      .other-content {
        width: 260px;
      }
    }
  }
}

.ivu-anchor-link {
  font-size: 15px !important;
  margin-bottom: 6px;
}
.ivu-anchor-link-active {
  font-size: 15px !important;
}

.ivu-anchor-ink-ball {
  width: 3px !important;
  height: 20px !important;
  border: none !important;
  border-radius: 0;
  background-color: #2d8cf0 !important;
}
// .ivu-anchor-link-active {
//   position: relative;
// }
// .ivu-anchor-link-active::after {
//   content: " ";
//   position: absolute;
//   right: 35%;
//   top: 0;
//   width: 3px;
//   height: 30px;
//   background-color: #2d8cf0;
// }
</style>
