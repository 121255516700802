<template>
	<div class="guideCenter">

		<!-- 基本信息 -->
		<item-cell id="jibenxinxi" title="基本信息"> 
			<TwoColumnTable :items="baseInfo"></TwoColumnTable> 
		</item-cell>  

		<!--申请材料 -->
		<item-cell id="banliliucheng" title="办理流程"> 

			<div>
				<el-steps direction="vertical" active="4" process-status="error">
					<el-step title="用户登录">
						<div slot="description" class="liucheng-login" style="margin-top: 20px; color: #333"> 
							<div style="font-size: 20px; font-weight: 700;">统一登录，验证身份信息 </div>
							<el-button  type="primary" round class="btn"  @click="toLogin()">{{ loginStatus }}</el-button>
						</div>
					</el-step>

					<el-step title="填写表单">

					</el-step>
					<el-step title="提交材料">
						<div slot="description"> 
							<div class="content-wrap">
								<div class="material-group" v-if="groupArr && groupArr.length > 1" @click="selectGroup()">
									<p class="material-group-title">{{selectG.CONDITION_NAME}}</p>
									<image src="../../static/images/icon_arrow_down.png" mode="aspectFit" class="img"></image>
								</div>
								
								<div v-for="(data,index) in matInfo" :key="index" class="material-item">
									<div class="item-cell">
										<div class="left ftwt700" style="font-size: 16px; color: #333">
											材料名称：
										</div>
										<div class="right ftwt700" style="font-size: 16px; color: #333">
											{{data.MATERIAL_NAME}}
										</div>
									</div>
									<div class="item-cell">
										<div class="left ftwt700">
											材料要求：
										</div>
										<div class="right">
											<span>{{data.PAGETEXT}}</span><br />
											<span>材料形式: {{data.ZZHDZB_TEXT}}</span><br />
											<a class="text-blue" @click="showMore(data,'其他要求')">其他要求</a>
										</div>
									</div>
									<div class="item-cell">
										<div class="left ftwt700">
											来  源：
										</div>
										<div class="right">
											{{data.SOURCE_TYPE_TEXT}}<br />
											<a class="text-blue" v-if="data.SOURCE_EXPLAIN != '暂无数据'" @click="showMore(data,'来源说明')">来源说明</a>
										</div>
									</div>
									<div class="item-cell">
										<div class="left ftwt700">
											填报须知：
										</div>
										<div class="right text-blue">
											<a @click="showMore(data,'填报须知')">填报须知</a>
										</div>
									</div>

									<div class="item-cell">
										<div class="left ftwt700">
											资料下载：
										</div>
										<div class="right text-blue" >
											<a  
												@click="goFile(data.EXAMPLE_GUID,'样表下载')"  
											>
												样表下载
											</a>
											<br />
											<a  
												@click="goFile(data.FORM_GUID,'空表')"  
											>
												空表下载
											</a> 
										</div>
									</div>


									<div class="item-cell">
										<div class="left ftwt700">
											中介服务：
										</div>
										<div class="right">
											<p>{{data.INTERMEDIARYSERVICES}}</p>
										</div>
									</div>
								</div>

								<div class="zanwu-shuju" v-if="!matInfo||matInfo.length==0">
									<div class="tip">暂无数据</div>
								</div>

							</div>
						</div>
					</el-step>
					<el-step title="查看进度" >
						<div slot="description" class="liucheng-login" style="margin-top: 20px; color: #333"> 
							<div style="font-size: 20px; font-weight: 700;">你可以进入用户中心，点击查看项目进度</div>
							<el-button  type="primary" round class="btn"  @click="tojindu()">点击查看</el-button>
						</div>
					</el-step>
				</el-steps>
			</div>

			<!-- <div class="content-wrap">
				<div class="material-group" v-if="groupArr && groupArr.length > 1" @click="selectGroup()">
					<p class="material-group-title">{{selectG.CONDITION_NAME}}</p>
					<image src="../../static/images/icon_arrow_down.png" mode="aspectFit" class="img"></image>
				</div>
				
				<div v-for="(data,index) in matInfo" :key="index" class="material-item">
					<div class="item-cell">
						<div class="left ftwt700" style="font-size: 16px; color: #333">
							材料名称：
						</div>
						<div class="right ftwt700" style="font-size: 16px; color: #333">
							{{data.MATERIAL_NAME}}
						</div>
					</div>
					<div class="item-cell">
						<div class="left ftwt700">
							材料要求：
						</div>
						<div class="right">
							<span>{{data.PAGETEXT}}</span><br />
							<span>材料形式: {{data.ZZHDZB_TEXT}}</span><br />
							<a class="text-blue" @click="showMore(data,'其他要求')">其他要求</a>
						</div>
					</div>
					<div class="item-cell">
						<div class="left ftwt700">
							来  源：
						</div>
						<div class="right">
							{{data.SOURCE_TYPE_TEXT}}<br />
							<a class="text-blue" v-if="data.SOURCE_EXPLAIN != '暂无数据'" @click="showMore(data,'来源说明')">来源说明</a>
						</div>
					</div>
					<div class="item-cell">
						<div class="left ftwt700">
							填报须知：
						</div>
						<div class="right text-blue">
							<a @click="showMore(data,'填报须知')">填报须知</a>
						</div>
					</div>

					<div class="item-cell">
						<div class="left ftwt700">
							资料下载：
						</div>
						<div class="right text-blue" >
							<a  
								@click="goFile(data.EXAMPLE_GUID,'样表下载')"  
							>
								样表下载
							</a>
							<br />
							<a  
								@click="goFile(data.FORM_GUID,'空表')"  
							>
								空表下载
							</a> 
						</div>
					</div>


					<div class="item-cell">
						<div class="left ftwt700">
							中介服务：
						</div>
						<div class="right">
							<p>{{data.INTERMEDIARYSERVICES}}</p>
						</div>
					</div>
				</div>

				<div class="zanwu-shuju" v-if="!matInfo||matInfo.length==0">
					<div class="tip">暂无数据</div>
				</div>

			</div> -->
		</item-cell>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import ItemCell from "@/components/guideItemCell";
import TwoColumnTable from "@/components/twoColumnTable"; 

export default {
	name: "guideCenter",
	components: { ItemCell, TwoColumnTable },
	props: {
		// 基本信息
		baseInfo: {			
			type: Array,
			default: function () {
				return []
			}
		},
		// 范围条件
		rangeInfo: {
			type: Array,
			default: () => {
				return []
			}
		},
		// 办理流程
		processInfo: {
			type: Array,
			default: () => {
				return []
			}
		},
		// 申请材料
		groupArr: {
			type: Array,
			default: () => {
				return []
			}
		},
		matInfo: {
			type: Array,
			default: () => {
				return []
			}
		},
		// 咨询监督
		consultInfo: {
			type: Array,
			default: () => {
				return []
			}
		},
		// 窗口办理
		windowInfo: {
			type: Array,
			default: () => {
				return []
			}
		},
		// 许可收费
		chargeInfo: {
			type: Array,
			default: () => {
				return []
			}
		},
		// 中介服务
		intermediaryInfo: {
			type: Array,
			default: () => {
				return []
			}
		},
		// 设立依据
		shedingyiju: {
			type: Array,
			default: () => {
				return []
			}
		},
		// 法律救济
		lawInfo: {
			type: Array,
			default: () => {
				return []
			}
		}
	},
	computed: {
		// ...mapGetters({
		// 	childItem: "getCurrentDeclareItem",
		// }),
	},
	data() {
		return { 
			loginStatus: this.$store.getters.getUserInfo ? "您已登录" : "点我登录",
		}
	},
	created() { 
	},
	mounted() { 
	},
	methods: {
		/* 转跳到登录 */
		toLogin() {
			// 判断是否登录，没有则转跳
			if (this.$store.getters.getUserInfo) {  
					this.$message({
						message: '您已经登录',
						type: 'success'
					});
			} else {
				this.$router.push({ name: "login" });
			}
		},
		/* 查看进度 */
		tojindu() {
			// 判断是否登录，没有则转跳
			if (this.$store.getters.getUserInfo) {  
				this.$router.replace({ path: "/workplace/index" });
			} else {
				this.$router.push({ name: "login" });
			}
		},
		/* 材料点击 */
		selectGroup() {
			this.$message({
				message: '功能暂未开放，敬请期待！',
				type: 'warning'
			});
		},
		showMore(item,type){
			let content = '';
			if (type == '其他要求') {
				content =  '材料分类：' + (item.MATERIAL_TYPE_TEXT || '暂未分类') + '<br\>' + '材料备注：' + (item.REMARK || "暂无数据");
			}else  if(type == '来源说明') {
				content = item.SOURCE_EXPLAIN || "暂无数据";
			}else  if (type == '填报须知') {
				content = item.FILL_EXPLIAN || '暂无数据';
			}else if (type == '法定办结时限' || type == '承诺办结时限'){
				content = item.detail;
			}
			this.$alert(content, type, {
				dangerouslyUseHTMLString: true, 
				closeOnClickModal: true,
				closeOnPressEscape: true,
			});

		},
		goFile(data,type){ 
			console.log("样表下载", data)
			let targetHtml = `` 
			for ( const item of data ) {
				targetHtml +=  `<a href=${item.FILEPATH} target="_blank" referrerpolicy="no-referrer">${item.FILENAME}</a>` + `\n` 
			}
			this.$alert((targetHtml || "暂无数据" ), type, {
				dangerouslyUseHTMLString: true,
				closeOnClickModal: true,
				closeOnPressEscape: true,
			});
		},
	}
};
</script>

<style lang="scss" scoped>
// 用户登录
.liucheng-login {
	color: #000;
	.btn {  
		margin-top: 20px; 
		font-size: 16px; 
	}
}

.item-title {
	font-size: 16px;
	font-weight: 700;
	color: #333;
}

.item-cell {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-content: flex-start;
	color: rgb(143, 142, 142);
	.left {
		min-width: 80px;
	}
}

.ftwt700 {
	font-weight: 700;
}
.guideCenter {
	line-height: 30px;
}
.sbfx-items {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  .sbfx-cell {
    position: relative;
    padding: 16px 10px;
    border: 2px solid #f0f0f0;
    overflow: hidden;
    font-size: 14px;
	line-height: 16px;
	max-width: 350px;
    .sbfx-days-box {
      flex-shrink: 0;
      width: 90px;
      color: #999999;
      .sbfx-days {
        padding: 0 4px;
        color: #fd3938;
      }
      .sbfx-days-end {
        padding: 0 4px;
      }
    }
  }
  .sbfx-cell-on {
    border: 2px solid #4795f1;
  }
  .chooseActive {
    position: absolute;
    bottom: 0px;
    right: -20px;
    border-top: 20px solid #fff;
    border-right: 20px solid #fff;
    border-left: 20px solid #fff;
    border-bottom: 20px solid #4795f1;
    text-align: left;
    .choose-icon {
      position: absolute;
      bottom: -20px;
      color: #fff;
      right: -2px;
    }
  }
}
.cailiao-tip {
  margin-top: 20px;
  font-size: 13px;
  color: #000;
  background-color: #f0f0f0;
  border-radius: 6px;
  padding: 16px; 
}
.temp-no-data {
	padding: 10px 0;

}
</style>
<style lang="scss">
.html-box {
  img {
    width: 700px !important;
  }
}
</style>